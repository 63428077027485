<template>
    <div style="background: #fff;">
        
    <div style="width:1200px;margin-left:auto;margin-right: auto;background: #fff;">
        <template v-if="enterInfo">
            <div class="el-card-container">
                <el-card shadow="never">
                    <div class="enter-title-container">
                        <div class="enter-title-container-left">
                            <img :src="this.$oucy.ossUrl+enterInfo.enterpriseAvata" style="width: 100px;height: 100px;">
                            <div class="enter-title-container-left-info">
                                <div>
                                    <div><span class="pointer m-r-10" @click="$oucy.go('/ShopVip?enterpriseId='+enterInfo.id)">{{enterInfo.enterpriseName}}</span> 
                        <!--             <el-dropdown @command="handleCommand">
                                      <el-button type="primary" size="mini">
                                        切换企业<i class="el-icon-arrow-down el-icon--right"></i>
                                      </el-button>
                                      <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="(v,i) of enterpriseByUserList" :command="i"><div :class="{'select':v.id==enterprise.id}">{{v.enterpriseName}}</div></el-dropdown-item>
                                      </el-dropdown-menu>
                                    </el-dropdown> -->
                                    </div>
                                    <div class="text-left"><el-rate v-model="enterInfo.enterpriseStar" disabled></el-rate></div>
                                </div>
                                <div class="df-ai-c">
                                    <div style="" v-if="this.$store.state.status==0">
                                      企业认证：<el-button type="default" size="mini" disabled>未认证</el-button>
                                        <el-button @click="$oucy.go('/enter/identify')" type="text">立即认证>></el-button>
                                    </div>
                                    <div style="" v-if="this.$store.state.status==1">
                                        <el-button type="default" size="mini" disabled>已申请认证，待审核</el-button>
                                        <!-- <el-button @click="$oucy.go('/enter/identify')" type="text">立即认证>></el-button> -->
                                    </div>
                                    <div class="df-ai-c" style="color: #6e7077">                                    
                                        <div v-if="this.$store.state.status==2" class="m-r-30">
                                          企业认证：<i class="el-icon-success" style="color: #1aad19;"> 已认证</i>
                                        </div>
                                          <div v-if="this.$store.state.status==3" class="m-r-30">
                                            企业认证：<el-button type="danger" size="mini" @click="$oucy.go('/enter/identify')"><i class="el-icon-info"></i> 认证未通过</el-button>
                                                <el-button @click="$oucy.go('/enter/identify')" type="text">重新认证>></el-button>
                                            </div>
                                          <div class="m-r-30 df-ai-c m-l-30">
                                            店铺等级：
                                            <img src="@/assets/images/qiyeku/vip.png" style="height: 20px;" v-if="enterInfo.enterpriseSecurityDepositFee>0">
                                            <span v-else>普通店铺</span>
                                          </div>
                                          <div class="df-ai-c m-r-30">已缴纳保证金：¥ <span style="color: #da1f1f;font-size: 22px;">{{enterInfo.enterpriseSecurityDepositFee||0}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="enter-title-container-right">
                            <el-button icon="el-icon-setting" type="primary" @click="$oucy.go('/enter/enterpriseapply')">编辑信息</el-button>
                        </div>
                    </div>
                </el-card>
            </div>

<!--          店铺升级-->
          <div style="margin-bottom: 30px;">
            <img src="../../../assets/shengjibanner.png" style="width: 100%;cursor: pointer;" @click="gotoUpgrade">
          </div>

            <div class="enter-center-container cards">
                <div shadow="hover" class="card" @click="$oucy.go('/acc/StockCar')">
                    <div class="enter-center">
                        <div><img src="../../../assets/enter/jhc_icon.png"></div>
                        <div>进货车</div>
                    </div>
                </div>                
                <div shadow="hover" class="card" @click="$oucy.go('/enter/CardDetail')">
                    <div class="enter-center">
                        <div><img src="../../../assets/enter/qymp_icon.png"></div>
                        <div>企业名片</div>
                    </div>
                </div>
                <div shadow="hover" class="card">
                    <div class="enter-center" @click="$oucy.go('/enter/shop')">
                        <div><img src="../../../assets/enter/dpsj_icon.png"></div>
                        <div>店铺广告管理</div>
                    </div>
                </div>
                <div shadow="hover" class="card" @click="()=>{$message('开发中，敬请期待...')}">
                    <div class="enter-center">
                        <div><img src="../../../assets/enter/qygw_icon.png"></div>
                        <div>企业官网管理</div>
                    </div>
                </div>
                <div shadow="hover" class="card" @click="$oucy.go('/enter/CouponManagement')">
                    <div class="enter-center">
                        <div><img src="../../../assets/enter/yhq_icon.png"></div>
                        <div>企业优惠券管理</div>
                    </div>
                </div>
              <div shadow="hover" class="card">
                    <div class="enter-center" @click="$oucy.go('/enter/payment')">
                        <div><img src="../../../assets/enter/jfjl_icon.png"></div>
                        <div>缴费记录</div>
                    </div>
                </div>
            </div>

            <div class="enter-bottom-container">
                <div class="card2">
                    <div class="df-jc-s-b">
                        <div></div>
                        <el-button style=";color: #A5756A;" type="text" @click="$oucy.go('/acc/wallet')">进入钱包> </el-button>
                    </div>
                    <div class="text-center">
                        <span style="font-size: 32px;color: #ED6732;font-weight: bold;">¥{{enterpriseAmountIncome||0}}</span>
                    </div>
                    <div class="text-center c-1 m-t-30">                        
                        <span>收入总额(单位：元)</span>
                    </div>
                </div>
                <div class="card2" style="width: 325px;background: #FFF7EE;">
                    <div class="df-jc-s-b">
                        <div></div>
                        <el-button style=";color: #A5756A;" type="text" @click="$oucy.go('/enter/OMS')">订单管理> </el-button>
                    </div>
                    <div class="text-center">
                        <span style="font-size: 32px;color: #ED6732;font-weight: bold;">{{enterpriseOrderCount||0}}</span>
                    </div>
                    <div class="text-center c-1 m-t-30">                        
                        <span>订单总数</span>
                    </div>
                </div>

                <div class="card2" style="width: 325px;background: #FFF7EE;">
                    <div class="df-jc-s-b" style="height:40px">
                        <!-- <div></div> -->
                    </div>
                    <div class="text-center">
                        <span style="font-size: 32px;color: #ED6732;font-weight: bold;">{{enterpriseBrowseCount||0}}</span>
                    </div>
                    <div class="text-center c-1 m-t-30">                        
                        <span>店铺浏览量</span>
                    </div>
                </div>

            </div>

        </template>

    </div>
    </div>
</template>

<script>
    import oucy from "../../../util/oucyUtil";
    import {userAuth,enterpriseinfo} from '@/service/index.js'
    import {localSet, localGet, localDel} from "@/store/store"
    export default {
        name: "Index",
        data(){
            return{
                enterInfo:null,
                enterpriseByUserList:[],
                enterprise:{},
                enterpriseAmountIncome:0,
                enterpriseBrowseCount:0,
                enterpriseOrderCount:0,
            }
        },
        mounted() {
            const me = this;
            let enterprise=localGet('enterprise')

                // 查询客户端用户的所有企业
            userAuth.getEnterpriseByUser({}).then(res=>{
               console.log(res)
               if(res && res.length){
                     this.enterprise = res[0]
                    for(let v of res){
                        if(v.isdefault){
                            this.enterprise =v
                        }
                    }                      
                    this.enterpriseByUserList=res
                    localSet('enterprise',this.enterprise)
                   oucy.queryEnterApplyInfo({enterpriseId:this.enterprise.id}).then(res=>{
                       console.log(res);
                       me.enterInfo = res;
                   })
                   this.queryEnterpriseByEnterpriseId()
               }else{
                    this.$oucy.replace('/acc/enterprise')
               }
            })
            
        },
        methods:{
            queryEnterpriseByEnterpriseId(){
                enterpriseinfo.queryEnterpriseByEnterpriseId({enterpriseId:this.enterprise.id}).then(res=>{
                    if(res){
                        this.enterpriseAmountIncome=res.enterpriseAmountIncome
                        this.enterpriseBrowseCount=res.enterpriseBrowseCount
                        this.enterpriseOrderCount=res.enterpriseOrderCount
                    }
                })
            },
            handleCommand(i) {
                let enterprise=this.enterpriseByUserList[i]
                // console.log('this.enterprise',this.enterprise)
                // console.log('enterprise',enterprise)
                // console.log('this.enterpriseByUserList',this.enterpriseByUserList)
                // console.log('this.enterpriseByUserList[i]',this.enterpriseByUserList[i])
                if(this.enterprise.id!=enterprise.id){
                    this.enterprise=enterprise
                    localSet('enterprise',enterprise)
/*                    setTimeout(()=>{
                        location.reload();
                    },500)*/
                    this.$oucy.replace('/acc/enterprise')
                }
            },
          gotoUpgrade: function (){
              this.$router.push({
                name: 'upgradeSelect'
              })
          }
        }
    }
</script>

<style scoped lang="less">
    .el-card-container{
        margin-bottom: 25px;
    }
    .enter-title-container{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
    .enter-title-container-left{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .enter-title-container-right{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .enter-title-container-left-info{
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 80%;
        padding: 0 10px;
    }

    .enter-center-container{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
    .enter-center{
        width: 170px;
        height: 138px;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
    }

    .enter-bottom-container{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        margin: 30px 0;
        padding-bottom: 60px;
    }
    .enter-bottom{
        width: 260px;
        line-height: 100px;
        text-align: center;
    }
    .enter-bottom-container-card{
        width: 30%;
        /*flex: 1;*/
    }
    .select{
        color: #409EFF;
    }
    /deep/ .el-card{
        border: none;
    }
    .cards{
        .card{
            background: #F8F8F8;
            border-radius: 10px;
        }
    }
    .card2{
        width: 480px;
     background: #FFF5F2;
     border-radius: 10px;   
     padding: 14px 30px;
     box-sizing:border-box;
    }
</style>
